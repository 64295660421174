// Import everything from autoload folder
import './autoload/ada-compliance.js'; import './autoload/foundation.js'; // eslint-disable-line

// Import local dependencies
import './plugins/lazyload';
import './plugins/modernizr.min';
import './plugins/spincrement';
import 'slick-carousel';
import 'jquery-match-height';
import objectFitImages from 'object-fit-images';
import '@fancyapps/fancybox/dist/jquery.fancybox.min';
// import { jarallax, jarallaxElement } from 'jarallax';
// import ScrollOut from 'scroll-out';

import { MarkerClusterer } from '@googlemaps/markerclusterer';
/* eslint-disable */
/* global google */

/**
 * Init foundation
 */
$(document).foundation();

/*
 *  This function will add a marker to the selected Google Map
 */
var infowindow;
let isClicked = false;

function add_marker($marker, map) {
  // var
  var latlng = new google.maps.LatLng(
    $marker.attr('data-lat'),
    $marker.attr('data-lng')
  );
  // create marker
  const dataId = $marker.attr('data-id');
  const dataName = $marker.attr('data-p_name');
  const dataStatus = $marker.attr('data-p_status');
  const dataColor = $marker.attr('data-color');
  const dataUrl = $marker.attr('data-url');

  var marker = new google.maps.Marker({
    position: latlng,
    map: map,
    id: dataId,
    color: dataColor,
    icon: {
      path: google.maps.SymbolPath.CIRCLE,
      scale: 10,
      fillColor: '#929292',
      fillOpacity: 1,
      strokeWeight: 0.6,
    },
    name: dataName,
    status: dataStatus,
    url: dataUrl,
  });

  // add to array
  map.markers.push(marker);

  // if marker contains HTML, add it to an infoWindow
  if ($.trim($marker.html())) {
    // create info window
    infowindow = new google.maps.InfoWindow();

    // show info window when marker is clicked

    google.maps.event.addListener(marker, 'click', function () {
      // Close previously opened infowindow, fill with new content and open it

      // map.setZoom(18);
      if (marker.status !== 'past') {
        infowindow.close();
        window.location.href = this.url;
        isClicked = true;
      } else {
        isClicked = false;
      }
      // map.setCenter(marker.getPosition());
      // infowindow.setContent($marker.html());
      // infowindow.open(map, marker);
    });

    //here's the pseudo-hover
    let timeout;
    google.maps.event.addListener(marker, 'mouseover', function () {
      if (!isClicked) {
        clearTimeout(timeout);
        infowindow.setContent($marker.html());
        infowindow.open(map, marker);
        infowindow.focus();
      }
    });
    // google.maps.event.addListener(marker, 'mouseout', function () {
    //   if (!isClicked) {
    //     timeout = setTimeout(() => {
    //       infowindow.close();
    //     }, '500');
    //   }
    // });
  }
}

// map_buttons.each(function (index) {
//
//
//   $(this).on('click', function () {
//     console.log(map.markers);
//   });
// });
/*
 *  This function will center the map, showing all markers attached to this map
 */
function center_map(map) {
  // vars
  var bounds = new google.maps.LatLngBounds();

  // loop through all markers and create bounds
  $.each(map.markers, function (i, marker) {
    var latlng = new google.maps.LatLng(
      marker.position.lat(),
      marker.position.lng()
    );
    bounds.extend(latlng);
  });

  // only 1 marker?
  if (map.markers.length == 1) {
    // set center of map
    map.setCenter(bounds.getCenter());
  } else {
    // fit to bounds
    map.fitBounds(bounds);
  }
}

/*
 *  This function will render a Google Map onto the selected jQuery element
 */
function render_map($el) {
  // var
  var $markers = $el.find('.marker');
  var styles = [
    {
      featureType: 'all',
      elementType: 'labels.text.fill',
      stylers: [
        {
          saturation: 36,
        },
        {
          color: '#333333',
        },
        {
          lightness: 40,
        },
      ],
    },
    {
      featureType: 'all',
      elementType: 'labels.text.stroke',
      stylers: [
        {
          visibility: 'on',
        },
        {
          color: '#ffffff',
        },
        {
          lightness: 16,
        },
      ],
    },
    {
      featureType: 'all',
      elementType: 'labels.icon',
      stylers: [
        {
          visibility: 'off',
        },
      ],
    },
    {
      featureType: 'administrative',
      elementType: 'geometry.fill',
      stylers: [
        {
          color: '#fefefe',
        },
        {
          lightness: 20,
        },
      ],
    },
    {
      featureType: 'administrative',
      elementType: 'geometry.stroke',
      stylers: [
        {
          color: '#fefefe',
        },
        {
          lightness: 17,
        },
        {
          weight: 1.2,
        },
      ],
    },
    {
      featureType: 'administrative.province',
      elementType: 'geometry',
      stylers: [
        {
          color: '#aeaeae',
        },
      ],
    },
    {
      featureType: 'administrative.province',
      elementType: 'geometry.fill',
      stylers: [
        {
          color: '#b04343',
        },
      ],
    },
    {
      featureType: 'landscape',
      elementType: 'geometry',
      stylers: [
        {
          color: '#f5f5f5',
        },
        {
          lightness: 20,
        },
      ],
    },
    {
      featureType: 'poi',
      elementType: 'geometry',
      stylers: [
        {
          color: '#f5f5f5',
        },
        {
          lightness: 21,
        },
      ],
    },
    {
      featureType: 'poi.medical',
      elementType: 'labels',
      stylers: [
        {
          visibility: 'off',
        },
      ],
    },
    {
      featureType: 'poi.park',
      elementType: 'geometry',
      stylers: [
        {
          color: '#dedede',
        },
        {
          lightness: 21,
        },
      ],
    },
    {
      featureType: 'poi.park',
      elementType: 'labels',
      stylers: [
        {
          visibility: 'off',
        },
      ],
    },
    {
      featureType: 'road.highway',
      elementType: 'geometry.fill',
      stylers: [
        {
          color: '#ffffff',
        },
        {
          lightness: 17,
        },
      ],
    },
    {
      featureType: 'road.highway',
      elementType: 'geometry.stroke',
      stylers: [
        {
          color: '#ffffff',
        },
        {
          lightness: 29,
        },
        {
          weight: 0.2,
        },
      ],
    },
    {
      featureType: 'road.arterial',
      elementType: 'geometry',
      stylers: [
        {
          color: '#ffffff',
        },
        {
          lightness: 18,
        },
      ],
    },
    {
      featureType: 'road.local',
      elementType: 'geometry',
      stylers: [
        {
          color: '#ffffff',
        },
        {
          lightness: 16,
        },
      ],
    },
    {
      featureType: 'transit',
      elementType: 'geometry',
      stylers: [
        {
          color: '#f2f2f2',
        },
        {
          lightness: 19,
        },
      ],
    },
    {
      featureType: 'water',
      elementType: 'geometry',
      stylers: [
        {
          color: '#e9e9e9',
        },
        {
          lightness: 17,
        },
      ],
    },
  ];

  // vars
  var args = {
    zoom: 16,
    center: new google.maps.LatLng(0, 0),
    mapTypeId: google.maps.MapTypeId.ROADMAP,
    scrollwheel: true,
    zoomControl: true,
    mapTypeControl: true,
    scaleControl: false,
    fullscreenControl: false,
    styles: styles, // Uncomment for map styling
  };

  // create map
  var map = new google.maps.Map($el[0], args);

  // add a markers reference
  map.markers = [];

  // add markers
  $markers.each(function () {
    add_marker($(this), map);
  });

  infowindow.addListener('closeclick', () => {
    isClicked = false;
  });

  google.maps.event.addListener(map, 'click', function () {
    infowindow.close();
    isClicked = false;
  });
  // center map
  center_map(map);

  let map_btn_marker = $('.portfolio-map-filter__filter-button');
  map.addListener('zoom_changed', () => {
    // let acf_map = ($('.acf-map'));
    // let map_canvas = acf_map.find('canvas');
    // console.log(map_canvas);
    map_btn_marker.each(function (index) {
      if ($(this).hasClass('igm-live-filter-active')) {
        let name_color = $(this).attr('data-color');
        setTimeout(function () {
          let acf_map_tab = $('.acf-map');
          let map_img_tab = acf_map_tab.find('img');
          map_img_tab.each(function () {
            $(this).parent().find($('p')).remove();
            if ($(this).parent().attr('aria-label') > 0) {
              $(this).css('display', 'none');
              $(this).parent().addClass('bg-color');
              $(this).parent().css('background', name_color);
              $(this).parent().find($('p')).remove();
              $(this)
                .parent()
                .append('<p>' + $(this).parent().attr('aria-label') + '</p>');
            }
          });
        }, 500);
      }
    });
  });
  map_btn_marker.each(function () {
    if ($(this).hasClass('igm-live-filter-active')) {
      // console.log($(this).attr('data-term'));
      let marker_color = $(this).attr('data-color');

      // console.log($(this).attr('data-color'));
      $markers.each(function () {
        for (let i = 0; i < $markers.length; i++) {
          map.markers[i].icon.fillColor = marker_color;
        }
        // map.markers = [];
      });
    }
    $(this).on('click', function () {
      // console.log($(this).attr('data-color'));
      let marker_color = $(this).attr('data-color');
      $markers.each(function () {
        for (let i = 0; i < $markers.length; i++) {
          map.markers[i].icon.fillColor = marker_color;
        }
        // map.markers = [];
      });
    });
  });
  // let color = '#676767';

  // add marker cluster
  function makeCluster(map, markers) {
    const renderer = {
      render({ count, position }) {
        const icon = {
          path: google.maps.SymbolPath.CIRCLE,
          scale: 16,
          fillColor: '#676767',
          // fillColor: color,
          fillOpacity: 0.7,
          strokeWeight: 0,
        };
        return new google.maps.Marker({
          label: { text: String(count), color: 'white', fontSize: '10px' },
          position,
          icon,
          zIndex: Number(google.maps.Marker.MAX_ZINDEX) + count,
          clusterClass: 'custom-cluster',
          averageCenter: true,
        });
      },
    };

    const markerCluster = new MarkerClusterer({ map, markers, renderer });
    return markerCluster;
  }

  const markerCluster = makeCluster(map, map.markers);
  // console.log(markerCluster);
  $('.igm-live-filter li').on('click', function () {
    markerCluster.clearMarkers();
    // color = $(this).data('color') ? $(this).data('color') : '#676767';
    for (let i = 0; i < map.markers.length; i++) {
      let filterName = $(this).data('name');
      let markerNames = map.markers[i].name;
      let markerStatus = map.markers[i].status;
      if (filterName === 'all' && markerStatus !== 'past') {
        map.markers[i].setVisible(true);

        markerCluster.addMarker(map.markers[i]);
      } else if (markerNames.includes(filterName) && markerStatus !== 'past') {
        map.markers[i].setVisible(true);
        markerCluster.addMarker(map.markers[i]);
      } else if (filterName === markerStatus) {
        map.markers[i].setVisible(true);
        markerCluster.addMarker(map.markers[i]);
      } else {
        map.markers[i].setVisible(false);
        markerCluster.removeMarker(map.markers[i]);
      }
    }
    center_map(map);
  });

  let categoryName = $('.igm-live-filter')
    .find('li.igm-live-filter-active')
    .data('name');
  if (categoryName) {
    markerCluster.clearMarkers();
    for (let i = 0; i < map.markers.length; i++) {
      let markerNames = map.markers[i].name;
      let markerStatus = map.markers[i].status;
      if (categoryName === 'all' && markerStatus !== 'past') {
        map.markers[i].setVisible(true);
        markerCluster.addMarker(map.markers[i]);
      } else if (
        markerNames.includes(categoryName) &&
        markerStatus !== 'past'
      ) {
        map.markers[i].setVisible(true);
        markerCluster.addMarker(map.markers[i]);
      } else if (categoryName === markerStatus) {
        map.markers[i].setVisible(true);
        markerCluster.addMarker(map.markers[i]);
      } else {
        map.markers[i].setVisible(false);
        markerCluster.removeMarker(map.markers[i]);
      }
    }
    center_map(map);
  }
}

/**
 * Fit slide video background to video holder
 */
function resizeVideo() {
  let $holder = $('.videoHolder');
  $holder.each(function () {
    let $that = $(this);
    let ratio = $that.data('ratio') ? $that.data('ratio') : '16:9';
    let width = parseFloat(ratio.split(':')[0]);
    let height = parseFloat(ratio.split(':')[1]);
    $that.find('.video').each(function () {
      if ($that.width() / width > $that.height() / height) {
        $(this).css({
          width: '100%',
          height: 'auto',
        });
      } else {
        $(this).css({
          width: ($that.height() * width) / height,
          height: '100%',
        });
      }
    });
  });
}

function loadPosts(response, taxonomy = false, page = 1) {
  let category = $('.igm-live-filter')
    .find('li.igm-live-filter-active')
    .data('name');
  $.ajax({
    type: 'POST',
    dataType: 'json',
    // eslint-disable-next-line no-undef
    url: ajax_object.ajax_url,
    data: {
      action: 'filtration_ajax',
      category: category,
      page: page,
    },
    success: function (response) {
      if (taxonomy) {
        $('#ajax-posts').html(response.html);
        $('.ajax-pagination').html(response.pagination);
      }
    },
    error: function (result) {
      console.warn(result);
    },
  });
  return false;
}

function getParameterByName(name, url) {
  if (!url) url = window.location.href;
  name = name.replace(/[/[/\]]/g, '\\$&');
  var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
    results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return '';
  return decodeURIComponent(results[2].replace(/\+/g, ' '));
}

/*
 *  This function will search query parametr in address bar for Portfolio page for filtration
 */
function clickOnFilter() {
  const buttons = document.querySelectorAll(
    '.portfolio-map-filter__filter-button'
  );
  if (!buttons) return;
  const queryString = window.location.search;
  if (!queryString) return;
  const urlParams = new URLSearchParams(queryString);
  const urlParamsName = urlParams.getAll('button-name');
  const urlParameter = urlParamsName.toString();

  const arr = [];
  buttons.forEach((button) => {
    arr.push(button.dataset?.name);
  });
  if (arr.indexOf(urlParameter) === -1) return;

  if (urlParameter == 'past') {
    $('.sorting').addClass('hide');
    $('.portfolio-past').removeClass('hide');
    $('.igm-live-filter li').removeClass('igm-live-filter-active');
    $(`.igm-live-filter li[data-name=${urlParameter}]`).addClass(
      'igm-live-filter-active'
    );
  } else {
    $('.igm-live-filter li').removeClass('igm-live-filter-active');
    $(`.igm-live-filter li[data-name=${urlParameter}]`).addClass(
      'igm-live-filter-active'
    );
    loadPosts('response', true);
  }
  $('.portfolio-subheading .h3').text('' + urlParameter + ' portfolio');
}

function triggerVideo() {
  $('.play-button').on('click', function () {
    const video = $(this).closest('.video-file-container').find('.video-file');
    if (!video.length) return;
    video.trigger('play');
  });
  $('.video-file').on('play', function () {
    const triggerVideoButton = $(this)
      .closest('.video-file-container')
      .find('.play-button')
      .fadeOut();
    if (!triggerVideoButton.length) return;
  });
  $('.video-file').on('pause', function () {
    const triggerVideoButton = $(this)
      .closest('.video-file-container')
      .find('.play-button')
      .fadeIn();
    if (!triggerVideoButton.length) return;
  });
}

/**
 * Scripts which runs after DOM load
 */
$(document).on('ready', function () {
  clickOnFilter();

  $('.single-slider').not('.slick-initialized').slick({
    infinite: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    dots: true,
    arrows: false,
    swipe: true,
  });

  $('.spincrement:eq(0)').spincrement({
    decimalPlaces: 1,
  });
  $('.spincrement:eq(1)').spincrement();
  $('.spincrement:eq(2)').spincrement();
  $('.spincrement:eq(3)').spincrement();
  $('.spincrement:eq(4)').spincrement();

  /**
   * Play Video File
   */
  triggerVideo();

  /*
   *  This function will render each map when the document is ready (page has loaded)
   */
  $('.acf-map').each(function () {
    render_map($(this));
  });

  $('.post__filter-button').on('click', function () {
    $('.post__filter-button').removeClass('igm-live-filter-active');
    $(this).toggleClass('igm-live-filter-active');
  });

  /**
   * Make elements equal height
   */

  // $('.igm-live-filter li').each(function () {
  //   let $name = $(this).html();
  //   let $dataName = $name.toLowerCase();
  //   $(this).attr('data-name', $dataName);
  // });

  $('.matchHeight').matchHeight();

  $('.timeline').slick({
    // infinite: true,
    arrows: false,
    slidesToShow: 7,
    slidesToScroll: 1,
    infinite: false,
    responsive: [
      {
        breakpoint: 1281,
        settings: {
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 640,
        settings: {
          slidesToShow: 2,
        },
      },
    ],
  });

  $('.timeline-slider').slick({
    // infinite: true,
    arrows: false,
    slidesToShow: 4,
    slidesToScroll: 1,
    infinite: false,
    responsive: [
      {
        breakpoint: 1281,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 640,
        settings: {
          slidesToShow: 2,
        },
      },
    ],
  });

  $('#market-list-js').slick({
    arrows: false,
    slidesToShow: 7,
    slidesToScroll: 1,
    infinite: false,
    rows: 0,
    responsive: [
      {
        breakpoint: 1320,
        settings: {
          slidesToShow: 3,
          arrows: true,
        },
      },
      {
        breakpoint: 950,
        settings: {
          slidesToShow: 2,
          arrows: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          arrows: true,
        },
      },
    ],
  });

  $('#market-list-big-js').slick({
    arrows: false,
    slidesToShow: 4,
    slidesToScroll: 1,
    infinite: false,
    responsive: [
      {
        breakpoint: 1320,
        settings: {
          slidesToShow: 3,
          arrows: true,
        },
      },
      {
        breakpoint: 950,
        settings: {
          slidesToShow: 2,
          arrows: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          arrows: true,
        },
      },
    ],
  });

  /**
   * IE Object-fit cover polyfill
   */
  if ($('.of-cover').length) {
    objectFitImages('.of-cover');
  }

  /**
   * Add fancybox to images
   */
  // $('.gallery-item')
  //   .find('a[href$="jpg"], a[href$="png"], a[href$="gif"]')
  //   .attr('rel', 'gallery')
  //   .attr('data-fancybox', 'gallery');
  // $(
  //   '.fancybox, a[rel*="album"], a[href$="jpg"], a[href$="png"], a[href$="gif"]'
  // ).fancybox({
  //   minHeight: 0,
  //   helpers: {
  //     overlay: {
  //       locked: false,
  //     },
  //   },
  // });

  /**
   * Init parallax
   */
  // jarallaxElement();
  // jarallax(document.querySelectorAll('.jarallax'), {
  //   speed: 0.5,
  // });

  /**
   * Detect element appearance in viewport
   */
  // ScrollOut({
  //   offset: function() {
  //     return window.innerHeight - 200;
  //   },
  //   once: true,
  //   onShown: function(element) {
  //     if ($(element).is('.ease-order')) {
  //       $(element)
  //         .find('.ease-order__item')
  //         .each(function(i) {
  //           let $this = $(this);
  //           $(this).attr('data-scroll', '');
  //           window.setTimeout(function() {
  //             $this.attr('data-scroll', 'in');
  //           }, 300 * i);
  //         });
  //     }
  //   },
  // });

  /**
   * Remove placeholder on click
   */
  const removeFieldPlaceholder = () => {
    $('input, textarea').each((i, el) => {
      $(el)
        .data('holder', $(el).attr('placeholder'))
        .on('focusin', () => {
          $(el).attr('placeholder', '');
        })
        .on('focusout', () => {
          $(el).attr('placeholder', $(el).data('holder'));
        });
    });
  };

  removeFieldPlaceholder();

  $(document).on('gform_post_render', () => {
    removeFieldPlaceholder();
  });

  /**
   * Scroll to Gravity Form confirmation message after form submit
   */
  $(document).on('gform_confirmation_loaded', function (event, formId) {
    let $target = $('#gform_confirmation_wrapper_' + formId);
    if ($target.length) {
      $('html, body').animate({ scrollTop: $target.offset().top - 50 }, 500);
      return false;
    }
  });

  /**
   * Hide gravity forms required field message on data input
   */
  $('body').on(
    'change keyup',
    '.gfield input, .gfield textarea, .gfield select',
    function () {
      let $field = $(this).closest('.gfield');
      if ($field.hasClass('gfield_error') && $(this).val().length) {
        $field.find('.validation_message').hide();
      } else if ($field.hasClass('gfield_error') && !$(this).val().length) {
        $field.find('.validation_message').show();
      }
    }
  );

  /**
   * Add `is-active` class to menu-icon button on Responsive menu toggle
   * And remove it on breakpoint change
   */
  $(window)
    .on('toggled.zf.responsiveToggle', function () {
      $('.menu-icon').toggleClass('is-active');
    })
    .on('changed.zf.mediaquery', function () {
      $('.menu-icon').removeClass('is-active');
    });

  /**
   * Close responsive menu on orientation change
   */
  $(window).on('orientationchange', function () {
    setTimeout(function () {
      if ($('.menu-icon').hasClass('is-active') && window.innerWidth < 641) {
        $('[data-responsive-toggle="main-menu"]').foundation('toggleMenu');
      }
    }, 200);
  });

  resizeVideo();

  /**
   * Scripts for number counter in hero section
   */
  function animate(obj, initVal, lastVal, duration) {
    let startTime = null;
    //get the current timestamp and assign it to the currentTime variable
    let currentTime = Date.now();
    //pass the current timestamp to the step function
    const step = (currentTime) => {
      //if the start time is null, assign the current time to startTime
      if (!startTime) {
        startTime = currentTime;
      }
      //calculate the value to be used in calculating the number to be displayed
      const progress = Math.min((currentTime - startTime) / duration, 1);
      //calculate what to be displayed using the value gotten above
      obj.innerHTML = Math.floor(progress * (lastVal - initVal) + initVal);
      //checking to make sure the counter does not exceed the last value (lastVal)
      if (progress < 1) {
        window.requestAnimationFrame(step);
      } else {
        window.cancelAnimationFrame(window.requestAnimationFrame(step));
      }
    };
    //start animating
    window.requestAnimationFrame(step);
  }
});

/**
 * Scripts which runs after all elements load
 */
$(window).on('load', function () {
  // jQuery code goes here

  $(document).on('click', '.post-link', function () {
    let cookieCat = $(this).attr('data-term');
    document.cookie = 'cat =  ' + cookieCat + ' ; path=/ ;';
  });

  $(document).on('click', '.portfolio-map-filter__filter-button', function () {
    let cookieCatPage = $(this).attr('data-term');
    document.cookie = 'catPage =  ' + cookieCatPage + ' ; path=/ ;';
  });

  let $preloader = $('.preloader');
  if ($preloader.length) {
    $preloader.addClass('preloader--hidden');
  }

  $('.igm-live-filter li').on('click', function () {
    let $name = $(this).data('name');
    let $title = $(this).data('title');
    $('.igm-live-filter li').removeClass('igm-live-filter-active');
    $(this).addClass('igm-live-filter-active');

    if ($name == 'past') {
      $('.sorting').addClass('hide');
      $('.portfolio-past').removeClass('hide');
      $('.potfolio-map h1').text('' + $name + ' portfolio');
      $('.portfolio-subheading .h3').text('' + $name + ' portfolio');
    } else if ($name == 'all') {
      loadPosts('response', true);
      $('.sorting').removeClass('hide');
      $('.portfolio-past').addClass('hide');
      $('.potfolio-map h1').text('portfolio');
      $('.portfolio-subheading .h3').text('Current Projects');
    } else if ($name == 'development') {
      $('.sorting').removeClass('hide');
      $('.portfolio-past').addClass('hide');
      $('.potfolio-map h1').text('' + $title + ' portfolio');
      $('.portfolio-subheading .h3').text('' + $title + ' portfolio');
      loadPosts('response', true);
    } else {
      $('.sorting').removeClass('hide');
      $('.portfolio-past').addClass('hide');
      loadPosts('response', true);
      $('.potfolio-map h1').text('' + $title + ' portfolio');
      $('.portfolio-subheading .h3').text('' + $title + ' portfolio');
    }
  });

  let map_buttons = $('.portfolio-map-filter__filter-button');

  let acf_map = $('.acf-map');
  let map_img = acf_map.find('img');

  map_img.each(function () {
    if ($(this).parent().attr('aria-label') > 0) {
      $(this).css('display', 'none');
      $(this).parent().addClass('bg-color');
      $(this)
        .parent()
        .append('<p>' + $(this).parent().attr('aria-label') + '</p>');
    }
  });

  map_buttons.each(function (index) {
    if ($(this).hasClass('igm-live-filter-active')) {
      // console.log($(this).attr('data-term'));
      let name_color = $(this).attr('data-color');
      setTimeout(function () {
        let acf_map_tab = $('.acf-map');
        let map_img_tab = acf_map_tab.find('img');
        map_img_tab.each(function () {
          $(this).parent().find($('p')).remove();
          if ($(this).parent().attr('aria-label') > 0) {
            $(this).parent().find($('p')).remove();
            $(this).css('display', 'none');
            $(this).parent().addClass('bg-color');
            $(this).parent().css('background', name_color);
            $(this)
              .parent()
              .append('<p>' + $(this).parent().attr('aria-label') + '</p>');
          }
        });
      }, 500);
    }

    $(this).on('click', function () {
      let name_color = $(this).attr('data-color');
      setTimeout(function () {
        let acf_map_tab = $('.acf-map');
        let map_img_tab = acf_map_tab.find('img');
        map_img_tab.each(function () {
          $(this).parent().find($('p')).remove();
          if ($(this).parent().attr('aria-label') > 0) {
            $(this).parent().find($('p')).remove();
            $(this).css('display', 'none');
            $(this).parent().addClass('bg-color');
            $(this).parent().css('background', name_color);
            $(this)
              .parent()
              .append('<p>' + $(this).parent().attr('aria-label') + '</p>');
          }
        });
      }, 500);
    });
  });

  // $('.acf-map').bind('mousewheel', function(e){
  //   map_buttons.each(function (index) {
  //     if($(this).hasClass('igm-live-filter-active')){
  //       let name_color = $(this).attr('data-color');
  //       setTimeout(function () {
  //         let acf_map_tab = $('.acf-map');
  //         let map_img_tab = acf_map_tab.find('img');
  //         map_img_tab.each(function () {
  //           $(this).parent().find($('p')).remove();
  //           if ($(this).parent().attr('aria-label') > 0) {
  //             $(this).css('display', 'none');
  //             $(this).parent().addClass('bg-color');
  //             $(this).parent().css('background', name_color);
  //             $(this).parent().find($('p')).remove();
  //             $(this)
  //               .parent()
  //               .append('<p>' + $(this).parent().attr('aria-label') + '</p>');
  //           }
  //         });
  //       }, 1000);
  //     }
  //   });
  // });
  $(document)
    .find('.ajax-pagination')
    .on('click', 'a.pagination', function (e) {
      let navPagination =
        getParameterByName('paged', $(this).attr('href')) ?? 1;
      e.preventDefault();
      e.stopPropagation();
      $(this).text();
      // loadDevelopmentPosts('response', true, navPagination);
      loadPosts('response', true, navPagination);
    });

  let url_string = window.location.href;
  let url = new URL(url_string);
  let paramValue = url.searchParams.get('category');

  // let catName = $('.igm-live-filter').find("[data-name='" + paramValue + "']");
  // if (catName.length) {
  //   catName.trigger('click');
  //   console.log(catName);
  //
  //   $.ajax({
  //     type: 'POST',
  //     dataType: 'json',
  //     // eslint-disable-next-line no-undef
  //     url: ajax_object.ajax_url,
  //     data: {
  //       action: 'filtration_ajax',
  //       category: paramValue,
  //     },
  //     success: function (response) {
  //       window.history.replaceState(null, null, window.location.pathname);
  //       $('#ajax-posts').html(response.html);
  //       $('.ajax-pagination').html(response.pagination);
  //     },
  //
  //     error: function (result) {
  //       console.warn(result);
  //     },
  //   });
  // }
});

/**
 * Scripts which runs at window resize
 */
$(window).on('resize', function () {
  // jQuery code goes here

  resizeVideo();
});

/**
 * Scripts which runs on scrolling
 */
$(window).on('scroll', function () {
  // jQuery code goes here
});
